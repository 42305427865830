const axios = require('axios').default;
let accessToken = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null;

export function post(data, api) {
    return axios.post(process.env.REACT_APP_SERVER_API + api + data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
}

export function postWithBody(data, api) {
    let config = {
        headers: {
            'Authorization': "Bearer " + accessToken
        }
    };

    return axios.post(process.env.REACT_APP_SERVER_API + api, data, config)
        .then(function (response) {
            return response.data;
        }).catch(function (error) {
            return error;
        });
}

export function getWithQueryString(data, api) {
    return axios.get(process.env.REACT_APP_SERVER_API + api + data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
}
export function getWithQueryStringV2(data, api) {
    let serv = process.env.REACT_APP_SERVER_API.replace('/api/v3', '/api-v2')
    return axios.get(serv + api + data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
}
export function get(data, api) {
    let final_api = "/api" + api;
    let config = {
        headers: {
            'Authorization': "Bearer " + process.env.REACT_APP_ACCESS_TOKEN_V2,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
    };
    if (process.env.REACT_APP_USE_PHP_PROXY === 'true') {
        config.headers['X-Proxy-Url'] = process.env.REACT_APP_SERVER_API_V2 + api;
        config.headers['X-Skip-Proxy-Formatting'] = true;
        final_api = '/proxy.php';
    }
    return axios.get(final_api, config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function auth() {
    if (!accessToken) {
        let api = '/app/access_token';
        let permissionsArray = process.env.REACT_APP_PERMISSIONS.split(",");
        let permissions = '';
        permissionsArray.forEach((value) => {
            permissions = permissions + '&permissions[]=' + value;
        })
        let queryString = '?app_id=' + process.env.REACT_APP_KEY + '&secret_key=' + process.env.REACT_APP_SECRET_KEY + permissions;
        return post(queryString, api).then(function (response) {
            accessToken = response.operation_result.access_token;
            localStorage.setItem('access_token', accessToken);
            return accessToken;
        });
    }
    return Promise.resolve(accessToken);
}

export function createReservation(data) {
    let api = '/widget_reservation';
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString();
    return post(queryString, api);

}

export function joinWaitlist(data) {
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    let api = '/outlet/' + oid + '/widget/waitlist';
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString();
    return post(queryString, api);

}

export function getAllowedDays(data) {
    let api = '/outlet/' + data.outlet_id + '/allowed_days';
    let queryString = '?app_access_token=' + accessToken;
    return post(queryString, api).then(function (response) {
        return response;
    });

}

export function getWidgetBootstrap(data) {
    let api = '/outlet/' + data.outlet_id + '/widget_bootstrap';
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString();
    return post(queryString, api).then(function (response) {
        return response;
    });

}

export function getVenueSettings(data) {
    let api = '/venues/' + data.outlet_id + '/settings?filter[section][]=widget&filter[section][]=feedback&filter[section][]=venue';
    let queryString = '&app_access_token=' + accessToken;
    return get(queryString, api).then(function (response) {
        if (response.data && response.data.length > 0) {
            return response;
        } else {
            let api = '/setting?filter[section][]=widget';
            let queryString = '?app_access_token=' + accessToken;
            return get(queryString, api).then(function (response) {
                return response;
            })
        }
    });
}

export function getShifts(data) {
    let query = new URLSearchParams(window.location.search);
    data.online_channel = 'widget';
    if (query.get("source") == 'facebook' || query.get("source") == 'instagram') {
        data.online_channel = query.get("source");
    }
    let api = '/outlet/' + data.outlet_id + '/shifts';
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString() + '&hide_passed_intervals=true';
    return getWithQueryString(queryString, api).then(function (response) {
        if (response.result && response.result.length > 0) {
            return response.result[0].shifts;
        } else {
            return [];
        }
    });
}

export function getShiftById(data) {
    let api = '/shifts/' + data.shift_id;
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString() + '&online_channel=widget' + '&hide_passed_intervals=true';
    return getWithQueryString(queryString, api).then(function (response) {
        if (response.result && response.result.shifts.length > 0) {
            return response.result.shifts;
        }
    });
}

export function getEventById(data) {
    let api = '/outlet/' + data.outlet_id + '/event/' + data.id + '?include[0]=payment_group_relations&include[1]=settings&include[2]=tags&include[3]=event_shift_relation';
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString() + '&online_channel=widget' + '&hide_passed_intervals=true';
    return getWithQueryString(queryString, api).then(function (response) {
        if (response.result) {
            return response.result.events;
        }
    });
}

export function getEventTimeslotsByDate(data) {
    let api = '/outlet/' + data.outlet_id + '/event/' + data.id + '/timeslots?date=' + data.date;
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString() + '&online_channel=widget' + '&hide_passed_intervals=true';
    return getWithQueryString(queryString, api).then(function (response) {
        if (response.result) {
            return response.result.events;
        }
    });
}

export function getDummyData(data) {
    return fetch('dummyData.json', {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then(function (response) {
            return response.json();
        })

}

export function checkCapacity(data) {
    let api = '/outlet/' + data.outlet_id + '/widget_capacity_check';
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString();
    return post(queryString, api).then(function (response) {
        return response;
    });

}

export function checkSectionCapacity(data) {
    let api = '/outlet/' + data.outlet_id + '/widget/section-capacity';
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString();
    return post(queryString, api).then(function (response) {
        return response;
    });
}

export function checkWidgetIntervalCapacity(data) {
    let api = '/outlet/' + data.outlet_id + '/widget/interval-capacity';
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString();
    return post(queryString, api).then(function (response) {
        return response;
    });
}

export function getReservationById(id, oid) {
    let api = '/outlet/' + oid + '/reservation/uuid/' + id;
    let queryString = '?app_access_token=' + accessToken + '&outlet_id=' + oid;
    return post(queryString, api).then(function (response) {
        if (response.result) {
            return response.result;
        } else {
            return {}
        }
    });
}

export function getReservationByUuid(uuid, oid) {
    let api = '/outlet/' + oid + '/reservation/uuid/' + uuid;
    let queryString = '?app_access_token=' + accessToken + '&outlet_id=' + oid;
    return post(queryString, api).then(function (response) {
        return response;
    });
}

export function cancelReservation(reservationId, cancellationCode, reason) {
    let api = '/reservation/' + reservationId + '/cancellation/' + cancellationCode;
    let data = '?cancellation_reason=' + reason;
    let queryString = '?app_access_token=' + accessToken + '&' + new URLSearchParams(data).toString();
    return post(queryString, api).then(function (response) {
        return response;
    });
}

export function existenceCheck(oid, email, phone) {
    let api = '/outlet/' + oid + '/cards/existence_check';
    let data = { "email": email, "phone": phone };

    return postWithBody(data, api).then(function (response) {
        if (response.status == 'failure') {
            let errorObj = {
                status: 'failure',
                source: response?.result?.errors?.source?.pointer ? response?.result?.errors?.source?.pointer : 'Email',
                message: response?.result?.errors?.detail ? response?.result?.errors?.detail : response?.message
            }
            return errorObj;
        } else {
            let successObj = {
                status: 'success',
                result: ''
            }
            return successObj;
        }
    });
}